import React from "react";
import DropdownMenu from "../Layout/DropdownMenu";
import "./IndexMapLegend.css";
import "../Layout/DropdownMenu.css";
import TemperatureScenarioSelector from "../Layout/TemperatureScenarioSelector";
import YearRangeSelector from "../Layout/YearRangeSelector";
import GradientLegend from "../Layout/GradientLegend";
import { wildfireStyle, heatwaveStyle, waterStressStyle, cycloneStyle, floodStyle, slrStyle } from "../Layout/LayerStyle";

const IndexMapLegend = (props) => {
  const {
    hazards,
    hazardScoreMetadata,
    selectedHazard,
    setSelectedHazard,
    temperatureScenarios,
    selectedTemperatureScenario,
    setSelectedTemperatureScenario,
    yearRanges,
    selectedYearRange,
    setSelectedYearRange,
    assetPointsVisible,
    setAssetPointsVisible,
    assetPointsWeightVisible,
    setAssetPointsWeightVisible,
    types,
  } = props;

  const display_hazards = {
    "WILDFIRE": "wildfire",
    "FLOOD": "flood",
    "HEATWAVE": "heatwave",
    "CYCLONE": "cyclone",
    "SEA LEVEL RISE": "SLR",
    "WATER STRESS": "water_stress"
  };

  const legendOptions = ["ASSET TYPE", "WILDFIRE", "FLOOD", "HEATWAVE", "CYCLONE", "SEA LEVEL RISE", "WATER STRESS"];

  const hazardLabelItems = [
    { name: "Low", color: "#89DA59" },
    { name: "Medium", color: "#FBB13C" },
    { name: "High", color: "#ff0000" },
  ];

  const polygonScoreItems = 
    { "wildfire": "Wildfire risk (%)",
      "flood": "Flood risk (%)",
      "cyclone": "Cyclone (CAT3+) risk (%)",
      "heatwave": "Heatwave days (%)",
      "SLR": "Sea level rise (m)",
      "water_stress": "Water stress risk (%)",
    };

  let items = hazards.includes(selectedHazard) ? hazardLabelItems : types;
  if (items.length === 0) {
    items = [
      {
        "color": "#99E6FF",
        "name": "Default"
      }
    ]
  }

  let scenarioLabel = selectedTemperatureScenario === "4.3°C" ? "ssp585" : "ssp245";
  let selectedHazardPropName = selectedHazard === 'SLR' ? 'sea_level_rise' : selectedHazard; // KEY

  let polyColorStyle = wildfireStyle;
  if (selectedHazard) {
    if (selectedHazard === "wildfire") {
      polyColorStyle = wildfireStyle;
    } else if (selectedHazard === "heatwave") {
      polyColorStyle = heatwaveStyle;
    }  else if (selectedHazard === "water_stress") {
      polyColorStyle = waterStressStyle;
    }  else if (selectedHazard === "cyclone") {
      polyColorStyle = cycloneStyle;
    }  else if (selectedHazard === "flood") {
      polyColorStyle = floodStyle;
    }  else if (selectedHazard === "SLR") {
      polyColorStyle = slrStyle;
    }
  }
  let gradientStartColor = polyColorStyle.paint["fill-color"][6];
  let gradientEndColor = polyColorStyle.paint["fill-color"][8];
  return (
    <div>

      <div className="map-legend">

      <DropdownMenu
        className="legend-dropdown"
        style={{ width: '500px' }}
        options={legendOptions}
        onSelect={(option) => {
          if (option === "asset type") {
            setSelectedHazard(null);
          } else {
            setSelectedHazard(display_hazards[option]);
          }
        }}
        label={"Layer Selector"}
      />
        {selectedHazard && (
          <>
          <h3 className="ssp-title">Risk Outlook</h3>
            
          </>
        )}

        {items.map((item) => (
          <div key={item.name} className="legend-item">
            <span
              className="legend-color"
              style={{ backgroundColor: item.color }}
            ></span>
            <span className="legend-text">{item.name}</span>
          </div>
        ))}

          <div className="asset-points-visibility">
            <input
              type="checkbox"
              checked={assetPointsVisible}
              onChange={(e) => setAssetPointsVisible(e.target.checked)}
              className="asset-points-visibility-checkbox"
            />
          <label className="asset-points-visibility-label">Show Asset Points</label>
          </div>
          <div className="asset-points-weighting-visibility">
            <input
              type="checkbox"
              checked={assetPointsWeightVisible}
              onChange={(e) => setAssetPointsWeightVisible(e.target.checked)}
              className="asset-points-weighting-visibility-checkbox"
            />
          <label className="asset-points-visibility-label">Show Portfolio Weight</label>
          </div>
          <h3 className="ssp-title">Climate Scenario</h3>
            <TemperatureScenarioSelector 
              temperatureScenarios={temperatureScenarios}
              selectedTemperatureScenario={selectedTemperatureScenario}
              setSelectedTemperatureScenario={setSelectedTemperatureScenario}
              />
          { selectedHazard && (
          <>
          <h3 className="ssp-title">Forecast Period</h3>
          <YearRangeSelector
            yearRanges={yearRanges}
            selectedYearRange={selectedYearRange}
            setSelectedYearRange={setSelectedYearRange}
            />

          <h3 className="ssp-title">{polygonScoreItems[selectedHazard]}</h3>
          <GradientLegend
            hazard={selectedHazard}
            startColor={gradientStartColor}
            endColor={gradientEndColor}
            startLabel={hazardScoreMetadata[scenarioLabel][selectedHazardPropName][selectedYearRange].min}
            endLabel={hazardScoreMetadata[scenarioLabel][selectedHazardPropName][selectedYearRange].max}
            />
           </>
          )}
      </div>
    </div>
  );
};

export default IndexMapLegend;
