
// Controls color and size of asset points at different zoom levels. ONLY NEED TO CHANGE THESE VARIABLES TO CHANGE POINT STYLE
const z5PointRadiusMin = 2;
const z5PointRadiusMax = 25;

const z10PointRadiusMin = 5;
const z10PointRadiusMax = 105;

// Controls color and size of asset points
const assetTypeStyle = {
  id: "point-layer",
  type: "circle",
  paint: {
      'circle-radius':  [
        'interpolate',
        ['linear'],
        ['zoom'],
        5, [ // Size at zoom level 5
          'interpolate',
          ['linear'], // Interoplate circle size linearly
          ['get', 'financialExposureWeight'],
          0, z5PointRadiusMin, // If financialExposureWeight is 0, circle radius is 2
          0.05, z5PointRadiusMax  // If financialExposureWeight is 0.01, circle radius is 25
        ],
        10, [ // Size at zoom level 10
          'interpolate',
          ['linear'], // Interoplate circle size linearly
          ['get', 'financialExposureWeight'],
          0, z10PointRadiusMin, // If financialExposureWeight is 0, circle radius is 5
          0.05, z10PointRadiusMax // If financialExposureWeight is 0.01, circle radius is 105
        ]
      ],
      'circle-color': [
        'match',
        ['get', 'type'], // Get the 'type' property from each feature
        'Default', '#ccc', // Quiet compiler warnings
        '#99e6ff' // Default color if none of the above types match
      ]
    }
};

const hazardLabelStyle = {
  id: "point-layer",
  type: "circle",
  paint: {
    'circle-radius': [
      'interpolate',
      ['linear'],
      ['zoom'],
      5, [
        'interpolate',
        ['linear'],
        ['get', 'financialExposureWeight'],
        0, z5PointRadiusMin, 
        0.05, z5PointRadiusMax 
      ],
      10, [
        'interpolate',
        ['linear'],
        ['get', 'financialExposureWeight'],
        0, z10PointRadiusMin,
        0.05, z10PointRadiusMax
      ]
    ],
    'circle-color': [
      'match',
      ['get', 'hazardSummaryLabel'], 
      'LOW', '#89DA59',
      'MEDIUM', '#FBB13C',
      'HIGH', '#ff0000',
      '#ccc' 
    ]
  }
};

// Controls color and size of asset points
const assetTypeStyleNoWeights = {
  id: "point-layer",
  type: "circle",
  paint: {
      'circle-radius':  [
        'interpolate',
        ['linear'],
        ['zoom'],
        5, z5PointRadiusMin,
        10, z10PointRadiusMin
      ],
      'circle-color': [
        'match',
        ['get', 'type'], // Get the 'type' property from each feature
        'Default', '#999999', // Quiet compiler warnings
        '#99e6ff' // Default color if none of the above types match
      ]
    }
};

const hazardLabelStyleNoWeights = {
  id: "point-layer",
  type: "circle",
  paint: {
    'circle-radius': [
      'interpolate',
      ['linear'],
      ['zoom'],
      5, z5PointRadiusMin,
      10, z10PointRadiusMin
    ],
    'circle-color': [
      'match',
      ['get', 'hazardSummaryLabel'], 
      'LOW', '#89DA59',
      'MEDIUM', '#FBB13C',
      'HIGH', '#ff0000',
      '#ccc' 
    ]
  }
};

const pointBorder = {
  id: 'border-layer',
  type: 'circle',
  paint: {
    'circle-color': '#000000',
    'circle-radius':  [
      'interpolate',
      ['linear'],
      ['zoom'],
      5, [
        'interpolate',
        ['linear'],
        ['get', 'financialExposureWeight'],
        0, z5PointRadiusMin + 1, 
        0.05, z5PointRadiusMax + 1 
      ],
      10, [
        'interpolate',
        ['linear'],
        ['get', 'financialExposureWeight'],
        0, z10PointRadiusMin + 1,
        0.05, z10PointRadiusMax + 1
      ]
    ],
  }
};

const pointBorderNoWeights = {
  id: 'border-layer',
  type: 'circle',
  paint: {
    'circle-color': '#000000',
    'circle-radius':  [
      'interpolate',
      ['linear'],
      ['zoom'],
      5, z5PointRadiusMin + 1,
      10, z10PointRadiusMin + 1
    ],
  }
};

// Polygon style for wildfire
const wildfireStyle = {
  id: "polygon-layer",
  type: "fill",
  paint: {
      'fill-color': [
        'interpolate',
        // ['exponential', 3],
        ['linear'],
        ['get', 'hazardSummaryLabel'],
        0, 'rgba(0, 0, 0, 0)', // Lowest limit value and color: WILL ALWAYS BE FULLY TRANSPARENT
        0.0001, 'rgba(255, 0, 180, 0.1)', // Lower limit value and color
        0.99, 'rgba(255, 0, 180, 1)' // Upper limit value and color
      ],
      'fill-outline-color': 'rgba(242, 229, 229, 0.1)'
    }
};

// Polygon style for heatwave
const heatwaveStyle = {
  id: "polygon-layer",
  type: "fill",
  paint: {
      'fill-color': [
        'interpolate',
        // ['exponential', 3],
        ['linear'],
        ['get', 'hazardSummaryLabel'],
        0, 'rgba(0, 0, 0, 0)', // Lowest limit value and color: WILL ALWAYS BE FULLY TRANSPARENT
        0.1, 'rgba(255, 0, 180, 0)', // Lower limit value and color
        0.2, 'rgba(255, 0, 180, 1)', // Middle limit value and color
        0.99, 'rgba(255, 0, 180, 1)' // Upper limit value and color
      ],
      'fill-outline-color': 'rgba(242, 229, 229, 0.1)'
    }
};

// Polygon style for water stress
const waterStressStyle = {
  id: "polygon-layer",
  type: "fill",
  paint: {
      'fill-color': [
        'interpolate',
        // ['exponential', 3],
        ['linear'],
        ['get', 'hazardSummaryLabel'],
        0, 'rgba(0, 0, 0, 0)', // Lowest limit value and color: WILL ALWAYS BE FULLY TRANSPARENT
        0.3, 'rgba(255, 0, 180, 0)', // Lower limit value and color
        0.99, 'rgba(255, 0, 180, 1)' // Upper limit value and color
      ],
      'fill-outline-color': 'rgba(242, 229, 229, 0.1)'
    }
};

// Polygon style for flood
const floodStyle = {
  id: "polygon-layer",
  type: "fill",
  paint: {
      'fill-color': [
        'interpolate',
        ['linear'],
        ['get', 'hazardSummaryLabel'],        
        0, 'rgba(0, 0, 0, 0)',
        0.0001, 'rgba(0, 255, 255, 0.1)',
        0.99, 'rgba(0, 255, 255, 1)'
      ],
      'fill-outline-color': 'rgba(242, 229, 229, 0.1)'
    }
};

// Polygon style for cyclone
const cycloneStyle = {
  id: "polygon-layer",
  type: "fill",
  paint: {
      'fill-color': [
        'interpolate',
        ['linear'],
        ['get', 'hazardSummaryLabel'],        
        0, 'rgba(0, 0, 0, 0)',
        0.0001, 'rgba(0, 255, 255, 0.1)',
        0.99, 'rgba(0, 255, 255, 1)'
      ],
      'fill-outline-color': 'rgba(242, 229, 229, 0.1)'
    }
};

// Polygon style for sea level rise
const slrStyle = {
  id: "polygon-layer",
  type: "fill",
  paint: {
      'fill-color': [
        'interpolate',
        ['linear'],
        ['get', 'hazardSummaryLabel'],        
        0, 'rgba(0, 0, 0, 0)',
        0.0001, 'rgba(0, 255, 255, 0.1)',
        0.99, 'rgba(0, 255, 255, 1)'
      ],
      'fill-outline-color': 'rgba(242, 229, 229, 0.1)'
    }
};

// EXAMPLES

// Polygon style for wildfire, heatwave, and water stress
// const hazardScorePolygonStyleHot = {
//   id: "polygon-layer",
//   type: "fill",
//   paint: {
//       'fill-color': [
//         'interpolate',
//         // ['exponential', 3],
//         ['linear'],
//         ['get', 'hazardSummaryLabel'],
//         0, 'rgba(0, 0, 0, 0)', // Lowest limit value and color: WILL ALWAYS BE FULLY TRANSPARENT
//         0.0001, 'rgba(255, 0, 0, 0.1)', // Lower limit value and color
//         0.99, 'rgba(255, 0, 0, 1)' // Upper limit value and color: UPPER LIMIT VALUE IS DYNAMICALLY REPLACED FROM META DATA
//       ],
//       'fill-outline-color': 'rgba(242, 229, 229, 0.1)'
//     }
// };

// Polygon style for flood, cyclone, and sea level rise
// const hazardScorePolygonStyleCool = {
//   id: "polygon-layer",
//   type: "fill",
//   paint: {
//       'fill-color': [
//         'interpolate',
//         ['linear'],
//         ['get', 'hazardSummaryLabel'],        
//         0, 'rgba(0, 0, 0, 0)',
//         0.0001, 'rgba(47, 0, 255, 0.1)',
//         0.99, 'rgba(47, 0, 255, 1)'
//       ],
//       'fill-outline-color': 'rgba(242, 229, 229, 0.1)'
//     }
// };


export { 
  assetTypeStyle,
  assetTypeStyleNoWeights,
  hazardLabelStyle,
  hazardLabelStyleNoWeights,
  pointBorder,
  pointBorderNoWeights,
  wildfireStyle,
  heatwaveStyle,
  waterStressStyle,
  cycloneStyle,
  floodStyle,
  slrStyle,
   };